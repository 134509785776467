<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    components: {
      Card: () => import("./CarList.vue"),
    },
    computed: {
      ...mapGetters("competitions", ["getCompetitions"]),
    },
    methods: {
      ...mapActions("competitions", ["getCompetitionsDb"]),
      createCompetition() {
        this.$router.push("/competition/createCompetition");
      },
    },
    async mounted() {
      await this.getCompetitionsDb();
    },
  };
</script>

<template>
  <div class="contetViewManagment">
    <div class="contetViewManagment__wrapper">
      <div class="contetViewManagment__header">
        <h1 class="contetViewManagment__title">Concursos</h1>
        <div class="contetViewManagment__btnCreate" @click="createCompetition" v-if="$user.role === 'superadmin'">
          <iconic name="plus" />
          <span class="tooltiptext">Crear concurso</span>
        </div>
      </div>
      <Card v-for="(competition, index) in getCompetitions" :key="index" :competition="competition" />
    </div>
  </div>
</template>

<style lang="scss">
  .contetViewManagment {
    @include Flex(row);
    width: 100%;
    &__wrapper {
      @include Flex(column);
      width: 100%;
      max-width: 1440px;
      gap: 20px;
    }
    &__title {
      font-size: 32px;
    }
    &__header {
      @include Flex(row, space-between);
      width: 100%;
      padding-top: 50px;
    }
    &__btnCreate {
      @include Flex(row);
      position: relative;
      width: 30px;
      height: 30px;
      color: #fff;
      background: $primary_color;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      .tooltiptext {
        position: absolute;
        top: 0px;
        left: 40px;
        width: 135px;
        padding: 3px 0 3px 5px;
        border-radius: 6px;
        background-color: #000;
        opacity: 0.8;
        visibility: hidden;
        &::after {
          content: "";
          position: absolute;
          top: 8px;
          left: -4px;
          margin-left: -10px;
          border-width: 7px;
          border-style: solid;
          border-color: transparent #000 transparent transparent;
        }
      }
      &:hover {
        transition: all 0.3s ease-in-out;
        transform: scale(1.05);
        .tooltiptext {
          visibility: visible;
        }
      }
    }
  }
</style>
